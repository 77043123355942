import React from 'react';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import qs from 'query-string';
import Theme from '../../Theme';
import * as Ant from 'antd';

const pageSize = 20;
const collectionName = 'user_profile';

const ExpertiseMap = {
  mechanical: {
    type: 'boolean',
    title: '機械',
  },
  operational: {
    type: 'boolean',
    title: '作業運輸',
  },
  biotechnology: {
    type: 'boolean',
    title: '生技醫藥',
  },
  chemical: {
    type: 'boolean',
    title: '化工',
  },
  electronic: {
    type: 'boolean',
    title: '電子及電力',
  },
  semiconductor: {
    type: 'boolean',
    title: '半導體製程及元件',
  },
  information: {
    type: 'boolean',
    title: '資通訊',
  },
  photoelectric: {
    type: 'boolean',
    title: '光電物理',
  },
  supplies: {
    type: 'boolean',
    title: '其他民生用品',
  },
};

function EngSearchUser(props) {
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [fetchResp, setFetchResp] = React.useState(null);
  const [searchName, setSearchName] = React.useState('');
  const [tmpSearchName, setTmpSearchName] = React.useState('');
  const [searchAgencyName, setSearchAgencyName] = React.useState('');
  const [tmpSearchAgencyName, setTmpSearchAgencyName] = React.useState('');
  const {action, id} = qs.parse(props.location.search);

  const fetchDocumentList = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      const extraQuery = {
        status: 'valid',
        member_id: {
          $ne: 0,
        },
      };

      if (searchName) {
        extraQuery['name_eng'] = searchName;
      } else {
        extraQuery.public = true;
      }

      if (searchAgencyName) {
        extraQuery['agency_name_eng'] = {
          $regex: searchAgencyName,
        };
      }

      const resp = await JStorage.fetchDocuments(
        collectionName,
        extraQuery,
        ['member_id'],
        paging,
      );

      setFetchResp(resp);
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }, [paging, searchName, searchAgencyName]);

  React.useEffect(() => {
    async function fetchData() {
      fetchDocumentList();
    }

    fetchData();
  }, [fetchDocumentList, action, id]);

  return (
    <Wrapper>
      <div className="main">
        <h1 style={{fontSize: 28}}>Search Members</h1>

        <form>
          <h2>Search Conditions</h2>

          <div className="field">
            <label>Name</label>
            <Ant.Input
              style={{flex: 1}}
              value={tmpSearchName}
              onChange={(e) => setTmpSearchName(e.target.value)}
            />
          </div>

          <div className="field">
            <label>Agency</label>
            <Ant.Input
              style={{flex: 1}}
              value={tmpSearchAgencyName}
              onChange={(e) => setTmpSearchAgencyName(e.target.value)}
            />
          </div>

          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Ant.Button
              type="link"
              style={{marginLeft: 6}}
              onClick={() => {
                setTmpSearchName('');
                setSearchName('');
                setTmpSearchAgencyName('');
                setSearchAgencyName('');
              }}>
              Clear
            </Ant.Button>

            <Ant.Button
              type="primary"
              onClick={() => {
                setSearchName(tmpSearchName);
                setSearchAgencyName(tmpSearchAgencyName);
              }}>
              Search
            </Ant.Button>
          </div>
        </form>

        <Ant.Table
          columns={[
            {
              title: 'Member #',
              key: 'member_id',
              render: (_, record) => {
                const idWithPadding = ('0000' + record?.member_id).slice(-4);
                return idWithPadding;
              },
            },
            {
              title: 'Name',
              key: 'name_eng',
              dataIndex: 'name_eng',
            },
            {
              title: 'Certificate #',
              key: 'certificate_number',
              dataIndex: 'certificate_number',
            },
            {
              title: 'Agency',
              key: 'agency_name_eng',
              render: (_, record) => {
                return record.public ? record.agency_name_eng : '';
              },
            },
            {
              title: 'Phone',
              key: 'phone',
              render: (_, record) => {
                if (!record.public) {
                  return '';
                }
                return record.agency_telephone?.replace('+886-', '0');
              },
            },
            {
              title: 'Expertise',
              key: 'expertise',
              render: (_, record) => {
                if (!record.public) {
                  return '';
                }

                const results = [];
                for (const k in record.expertise || {}) {
                  if (k === 'etc') {
                    continue;
                  } else if (k === 'etc_text') {
                    results.push(record.expertise[k]);
                  } else if (!record.expertise[k]) {
                    continue;
                  } else {
                    const field = ExpertiseMap[k];
                    results.push(field?.title || k);
                  }
                }
                return results.join(', ');
              },
            },
          ]}
          dataSource={fetchResp?.results}
          rowKey={'id'}
          pagination={{
            size: 'small',
            total: fetchResp?.total || 0,
            showSizeChanger: false,
            showTotal: (total) =>
              `Total ${total} records, ${pageSize} per page`,
            current: paging.offset / pageSize + 1,
            pageSize: pageSize,
            position: ['bottomCenter'],
            onChange: (page, pageSize) => {
              if (pageSize) {
                setPaging({
                  offset: (page - 1) * pageSize,
                  limit: pageSize,
                });
              }
            },
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: var(--contentMinHeight);

  & > .main {
    max-width: 800px;
    margin: 20px auto;

    h1 {
      text-align: center;
      padding-bottom: 15px;
      border-bottom: 4px solid ${Theme.colors.main};
      margin-bottom: 25px;
    }

    form {
      max-width: 600px;
      margin: 40px auto;
      padding: 20px;
      box-shadow: 0 5px 12px rgba(0, 0, 0, 0.3);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
`;

export default withPageEntry(EngSearchUser);
